
body{
    background: #ffffff;
    color: #FFF;
    height: 100%;
    outline: none !important;
    background-color: #16002F;
    position: relative;
  }
  html {
    height: 100%;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-track {
    width: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FAD536; 
  }
  /* Typography css begin */
  h5{
    font-size: 1rem;
  }
  
  a{
    color: #16002F !important;
    text-decoration: none;
  }
  
  /* Typography css end */
  
  /* Color css begin */
  .bg-primary{
    background: #923EA0 !important;
  }
  .bg-w{
    background: #F5F8FF;
  }
  .bg-t{
    background: transparent;
  }
  .fc-b{
    color: #923EA0;
  }
  .bg-l{
    background: #6351ce;
  }
  .bg-b{
    background: #923EA0;
  }
  .fc-w{
    color: #F1F6FE;
  }
  .bg-black{
    background-color: #000;
  }

  .bg-loading{
    background: #FBFBFB;
  }
  .bg-light-pink{
    background-color: #FFFF;
    border-radius: 10px;
    color: #923EA0;
  }
  .bg-pink{
    background-color: #f5e9b6;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0 6px 12px 0 #FFF
  }
  .bg-yellow{
    background-color: #f5e9b6;
  }
  .b-r{
    border-radius: 20px;
  }
  
  .pb-100{
    padding-bottom: 100px;
  }
  /* Color css end */
  
  /* Font size css begin */
    .fs-60{
        font-size: 60px;
    }
    .fs-10{
      font-size: 10px !important;
  }
    .fs-12{
      font-size: 12px !important;
  }
    .fs-14{
        font-size: 14px !important;
    }
    .fs-16{
      font-size: 16px !important;
  }
    .fs-20{
      font-size: 20px !important;
  }
  .fs-30{
    font-size: 30px !important;
}
  .fw-500{
    font-weight: 500 !important;
}

.text-green{
  color: #FAD536;
}
.yellow-shadow{
  box-shadow: 5px -5px 18px #FAD536;
}
.yellow-text-shadow{
  text-shadow: 2px 1px 5px #FAD536;
}

  /* Font size css end */
  
  /* Navbar css begin */
  .navbar{
    padding: 0.3rem;
  }
  .bg-light{
    background: #fff !important;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #FFF;
    font-size: 1.1rem;
    font-weight: 400;
  }
  .navbar-light .navbar-nav .nav-link:hover{
    color: #FFF;
  }
  .nav-link:focus {
    color: #FAD536 !important;
  }
  .nav-link:hover {
    color: #FAD536 !important;
  }

  .navbar-light .navbar-nav .nav-link.active, .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .show>.nav-link{
    color:#4957ed;
  }
  
  .navbar-nav li:hover > ul.dropdown-menu {
    display: block;
  } 
  .dropdown-submenu {
    position:relative;
  }
  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top:-6px;
  }
  
  .navbar .megamenu{ padding: 1rem;}
  .nav-pills .nav-link{
  color:#FFF;
  }
  .navbar-toggler{
    background-color: #fff;
  }

  
  @media screen and (max-width: 580px) {
    #navbar {
      padding: 20px 10px !important;
    }
  }

  
  /* ============ desktop view ============ */
  @media all and (min-width: 992px) {
    .navbar .has-megamenu{position:static!important;}
    .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }
  
  }	
  /* ============ mobile view ============ */
  @media(max-width: 991px){
    .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
      overflow-y: auto;
        max-height: 90vh;
        margin-top:10px;
    }
  }


  .colored-button-header:link,
  .colored-button-header:visited {
    padding: 10px 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid #FAD536 !important;
    border-radius: 12px;
    border: none;
    color: #FAD536 !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 500 !important;
    transition: all .3s ease-in-out;
    outline: none !important;
}

.colored-button-header:hover,
.colored-button-header:active {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #FAD536
}

.colored-button-hero,
.colored-button-hero:visited {
  padding: 10px 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #FAD536 !important;
  border-radius: 12px;
  border: none;
  color: #FAD536 !important;
  overflow: hidden;
  font-size: 14px !important;
  font-weight: 500 !important;
  transition: all .3s ease-in-out;
  outline: none !important;
  background-color: transparent;
}
.colored-button-hero:hover,
.colored-button-hero:active {
    transform: translateY(-2px);
}


  /* Navbar css end */
.footer-section{
  background-color: #1A0532;
  width: 100%;
  overflow: hidden;
}
.footer-icons{
  color: #ffffff;
  width: 35px;
  height: 35px;
}
body{ position: relative; }
.nav-dots{
  position: fixed;
   top:40%; 
   left:-23px;
  width:20px; 
}

.nav-dots .nav-link{ 
  display: block; 
  background: #eee; 
  border-radius: 10px; 
  margin-bottom: 10px; 
}
.nav-dots .nav-link.active{ background:#FAD536 !important; color:white !important; }

.hero-section-1{
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  border-left: 1px solid #FFF;
  color: #FAD536;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 30px;
  box-shadow: 15px 1px 1px #FAD536;
}
.hero-section-1{
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  border-left: 1px solid #FFF;
  color: #FAD536;
  padding-top: 44px;
  padding-bottom: 44px;
  border-radius: 30px;
  box-shadow: 15px 1px 1px #FAD536;
}
.hero-section-2{
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  border-left: 1px solid #FFF;
  color: #FAD536;
  padding-top: 45px;
  padding-bottom: 45px;
  border-radius: 30px;
  box-shadow: 15px 1px 1px #FAD536;
}
.hero-section-text{
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}
@font-face {
  font-family: myFirstFont;
  src: url(./digital-7.ttf);
}
.hero-section-number{
  font-size: 20px;
}

.card-basic,
.card-premium,
.card-standard {
  margin: 0 2rem 1rem 0;
  padding: 0 0 0.5rem 0;
  width: 20rem;
  background: #fff;
  color: #444;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
  overflow: hidden;
  transition: all 0.1ms ease-in-out;
}
.card-basic:hover,
.card-premium:hover,
.card-standard:hover {
  transform: scale(1.02);
}

.card-header {
  height: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.3rem;
  padding: 1rem 0;
  color: #fff;
  clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}

.header-basic,
.btn-basic {
  background: linear-gradient(135deg, rgb(0, 119, 238), #06c766);
}

.header-standard,
.btn-standard {
  background: linear-gradient(135deg, #b202c9, #cf087c);
}

.header-premium,
.btn-premium {
  background: linear-gradient(135deg, #eea300, #ee5700);
}

.card-body {
  padding: 0.5rem 0;
}
.card-body h2 {
  font-size: 2rem;
  font-weight: 700;
}

.card-element-container {
  font-size: 1em;
  font-weight: 500;
  color: #444;
  list-style: none;
}

.btn {
  margin: 0.5rem 0;
  padding: 0.7rem 1rem;
  outline: none;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
  border: none;
  cursor: pointer;
}

.btn:hover {
  color: #fff;
}

.btn:active {
  outline: none;
  box-shadow: none;
}

.card-element-hidden {
  display: none;
}
.rules-section{
  background-color: #4DD228;
  color: #FFF;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.sharing-section img{
  width: 80px;
  filter: grayscale(0%);
}
.sharing-section img:hover{
  filter: grayscale(100%);
}
.sharing-section{
  font-size: 10px;
  color: #000;
  background-color: #FFF;
  border-radius: 20px;
}
.footer-links a{
  color: #FFF !important;
  font-size: 18px;
}

#result {
  display:flex;
  justify-content: center;
  align-items: center;
  
  animation: color-mix 1s linear infinite alternate;
  border-radius: 8px;
}
@keyframes color-mix {
  0% { border-radius: 20px }
  100% { border-radius: 60px }
}

.btnf {
  width: 60px;
  height: 60px;
  margin: 10px;
  color: #3498db;
  overflow: hidden;
  position: relative;
  border-radius: 30%;
  background: #f1f1f1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btnf i {
  font-size: 26px;
  transition: 0.2s linear;
}
.btnf:before {
  content: "";
  width: 120%;
  height: 120%;
  left: -110%;
  top: 90%;
  position: absolute;
  background: #3498db;
  transform: rotate(45deg);
  background-image: radial-gradient(circle farthest-corner at 10% 20%, #56CCF2 0%, #3498db 100%);
}
.btnf:hover i {
  color: #f1f1f1;
  transform: scale(1.3);
}
.btnf:hover:before {
  top: -10%;
  left: -10%;
  -webkit-animation: animation1 0.7s 1;
          animation: animation1 0.7s 1;
}

@-webkit-keyframes animation1 {
  0% {
    top: 90%;
    left: -110%;
  }
  50% {
    top: -30%;
    left: 10%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}

@keyframes animation1 {
  0% {
    top: 90%;
    left: -110%;
  }
  50% {
    top: -30%;
    left: 10%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}


.hero-heading{
  margin: 0;
  padding: 0;
  font-size: 6em;
  font-weight: 800;
  color: #FAD536;
  text-transform: uppercase;
  transform: rotate(-15deg) skew(25deg);
  animation: animate 3s ease-in-out infinite;
}
  /* ============ mobile view ============ */
  @media(max-width: 991px){
    .hero-heading{
      font-size: 3em;
    }
  }



@keyframes animate 
{
  0%,100%
  {
    transform: rotate(-15deg) skew(25deg) translate(0,0);
   text-shadow: -5px 5px 0 rgba(73, 73, 73, 0.2);
  }
  50%
  {
    transform: rotate(-15deg) skew(25deg) translate(50px,-50px);
   text-shadow: -50px 50px 0 rgba(70, 70, 70, 0.2); 
 }
}

.footer-top-icons {
  transition: all .3s ease-in-out;
}
.footer-top-icons:hover{
  transform: translateY(-6px);
}

.flipper .num {
  font-family: myFirstFont !important;
  background-color:#ffefaa ;
  border: 1px solid #000;
  border-radius: 4px;
  color: #16002F;
  display: inline-block;
  padding: 1px 20px;
  position: relative;
  text-align: center;
  letter-spacing: 2px;
  font-size: 45px;
  font-weight: 500;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.45);
}
.flipper2 .num {
  font-family: myFirstFont !important;
  background-color:#ffefaa ;
  border: 1px solid #000;
  border-radius: 4px;
  color: #16002F;
  display: inline-block;
  padding: 1px 20px;
  position: relative;
  text-align: center;
  letter-spacing: 2px;
  font-size: 35px;
  font-weight: 500;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.45);
}